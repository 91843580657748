import React from "react";
import "./AbsenceSchedule.css";
import MasterCompanyLogged from "./MasterCompanyLogged";

const AbsenceSchedule = () => {
  return (
    <>
      <MasterCompanyLogged title="Ausências">
        <div className="main-employees"></div>
      </MasterCompanyLogged>
    </>
  );
};

export default AbsenceSchedule;
