import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import HomeCompany from "./HomeCompany";
import VerificationCode from "./Pages/VerificationCode";
import NoPage from "./Pages/NoPage";
import CompanyEmployees from "./Pages/CompanyEmployees";
import AbsenceSchedule from "./Pages/AbsenceSchedule";

function App() {
  //#####################################
  // var ua = navigator.userAgent || navigator.vendor || window.opera;
  // var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
  // var isFacebook = ua.indexOf("Facebook") > -1 ? true : false;
  // var isTwitter = ua.indexOf("Twitter") > -1 ? true : false;
  // var isMessenger = ua.indexOf("Messenger") > -1 ? true : false;
  // var isLine = ua.indexOf("Line") > -1 ? true : false;
  // var isWechat = ua.indexOf("Wechat") > -1 ? true : false;
  // var isMiui = ua.indexOf("Miui") > -1 ? true : false;
  // var isAndroid = /android/i.test(ua);
  // var isIOS = /iPad|iPhone|iPod/i.test(ua) && !window.MSStream;
  // var isWindowsPhone = /windows phone/i.test(ua);
  // var browserName =
  //   isAndroid == true ? "Chrome" : isIOS == true ? "Safari" : "Navegador";
  // if (document.documentElement.classList) {
  //   if (
  //     isInstagram ||
  //     isFacebook ||
  //     isTwitter ||
  //     isMessenger ||
  //     isLine ||
  //     isWechat ||
  //     isMiui
  //   ) {
  //     alert(`Este navegador não é seguro\n\n Por favor abra no ${browserName}`);
  //   }
  // }
  //#####################################
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomeCompany />} />
          <Route path="*" element={<NoPage />} />
          <Route path="HomeCompany" element={<HomeCompany />} />
          <Route path="VerificationCode" element={<VerificationCode />} />
          <Route path="CompanyEmployees" element={<CompanyEmployees />} />
          <Route path="AbsenceSchedule" element={<AbsenceSchedule />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
