import React from "react";
import "./ProfessionalCard.css";
import {
  faTrashAlt,
  faListOl,
  faCalendarAlt,
  faCalendarTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAgenda from "./ModalAgenda";
import ModalServices from "./ModalServices";
import ModalAbsenceFromWork from "./ModalAbsenceFromWork";

const ProfessionalCard = ({ profName = "Maria", children }) => {
  const [showScheduler, setshowScheduler] = React.useState(false);
  const [showServices, setshowServices] = React.useState(false);
  const [showAbsenceFromWork, setshowAbsenceFromWork] = React.useState(false);
  const openScheduler = () => {
    setshowScheduler(true);
  };
  const openServices = () => {
    setshowServices(true);
  };
  const openAbsence = () => {
    setshowAbsenceFromWork(true);
  };

  return (
    <div className="prof-card">
      <ModalAgenda
        show={showScheduler}
        onClose={setshowScheduler}
        title={profName}
      />
      <ModalServices
        show={showServices}
        onClose={setshowServices}
        title={profName}
      />
      <ModalAbsenceFromWork
        show={showAbsenceFromWork}
        onClose={setshowAbsenceFromWork}
        title={profName}
      />
      <label className="lbl2">Profissional</label>
      <label className="lbl1">{profName}</label>
      <div className="prof-card-options">
        <button className="btn-prof-option" onClick={openScheduler}>
          Agenda &nbsp;
          <FontAwesomeIcon icon={faCalendarAlt} />
        </button>
        <button className="btn-prof-option" onClick={openServices}>
          Serviços &nbsp;
          <FontAwesomeIcon icon={faListOl} />
        </button>
        <button className="btn-prof-option" onClick={openAbsence}>
          <FontAwesomeIcon icon={faCalendarTimes} />
        </button>
        <button className="btn-prof-option">
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    </div>
  );
};

export default ProfessionalCard;
