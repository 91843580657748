import shadow from "../files/shadow.png";
import entraNaFila from "../files/entra_na_fila_1.png";
import React from "react";
import { InputMask } from "@react-input/mask";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
Modal.setAppElement("#root");

const VerificationCode = () => {
  const [ignored, forceUpdate] = React.useReducer((o) => !o);
  const [showLoading1, setShowLoading1] = React.useState(false);
  const [showLoading2, setShowLoading2] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [code, setCode] = React.useState("");
  function fecharModal() {
    setShowModal(false);
  }
  const modify = (input) => {
    return { mask: input[0] === "0" ? "____" : undefined };
  };
  function message(txt) {
    setShowModal(true);
    setMsg(txt);
  }

  const navigate = useNavigate();

  const onClickHandler2 = function () {
    setShowLoading2(true);
    sessionStorage.setItem("jwtToken", ``);
    sessionStorage.setItem("login", ``);
    sessionStorage.setItem("name", ``);
    sessionStorage.setItem("refreshToken", ``);
    fetch(
      "https://yqk7t0y2w6.execute-api.us-east-1.amazonaws.com/Prod/User/login",
      {
        method: "POST",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: sessionStorage.getItem("phone"),
          code: ``,
          isWhatsapp: true,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setShowLoading2(false);
        if (
          (response?.jwtToken?.length ?? 0) > 0 &&
          (response?.login?.length ?? 0) > 0 &&
          (response?.refreshToken ?? 0)?.length > 0
        ) {
          sessionStorage.setItem("jwtToken", response.jwtToken);
          sessionStorage.setItem("login", response.login);
          sessionStorage.setItem("name", response.name);
          sessionStorage.setItem("refreshToken", response.refreshToken);
          message("você está logado");
        } else {
          message("Você receberá um novo código");
        }
      });
  };

  const onClickHandler1 = function () {
    setShowLoading1(true);
    sessionStorage.setItem("jwtToken", ``);
    sessionStorage.setItem("login", ``);
    sessionStorage.setItem("name", ``);
    sessionStorage.setItem("refreshToken", ``);
    fetch(
      "https://yqk7t0y2w6.execute-api.us-east-1.amazonaws.com/Prod/User/login",
      {
        method: "POST",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: sessionStorage.getItem("phone"),
          code: code,
          isWhatsapp: true,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setShowLoading1(false);
        if (
          (response?.jwtToken?.length ?? 0) > 0 &&
          (response?.login?.length ?? 0) > 0 &&
          (response?.refreshToken ?? 0)?.length > 0
        ) {
          sessionStorage.setItem("jwtToken", response.jwtToken);
          sessionStorage.setItem("login", response.login);
          sessionStorage.setItem("name", response.name);
          sessionStorage.setItem("refreshToken", response.refreshToken);
          // message("você está logado");
          navigate(`/CompanyEmployees`);
        } else {
          message("Codigo incorreto, você receberá um novo código");
        }
      });
  };

  return (
    <div className="App">
      <Modal
        className="modal-body"
        isOpen={showModal}
        onRequestClose={fecharModal}
        contentLabel="Modal de exemplo"
      >
        <div className="modal-body">
          <div className="modal-header">
            <button className="btn-close-modal" onClick={fecharModal}>
              X
            </button>
            <h2 className="modal-title">Olá</h2>
          </div>
          <div className="modal-content">
            <div>
              {Array.isArray(msg)
                ? msg.map((item) => {
                    return <p>{item}</p>;
                  })
                : msg}
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn-close-modal-footer" onClick={fecharModal}>
              Fechar
            </button>
          </div>
        </div>
      </Modal>
      <header className="App-header">
        <img src={shadow} className="header-shadow" alt="logo" />
        <div className="container-logo">
          <img src={entraNaFila} className="header-entranafila" alt="logo" />
        </div>
        <label className="ft-1 clr-1 ft-sz-2">
          ENTRA <br /> NA FILA{" "}
        </label>
        <div className="box-border-entra-na-fila">
          <label className="label-for-title">Insira o código</label>
          <InputMask
            placeholder="Ex: 1122"
            className="txt"
            mask="____"
            replacement={{ _: /\d/ }}
            modify={modify}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
        </div>
        <button className="btn-resend" onClick={onClickHandler2}>
          Enviar novamente
          {showLoading2 == true ? (
            <FontAwesomeIcon icon={faSync} className={"spinner"} />
          ) : null}
        </button>
        <button className="btn-entra-na-fila" onClick={onClickHandler1}>
          Verificar
          {showLoading1 == true ? (
            <FontAwesomeIcon icon={faSync} className={"spinner"} />
          ) : null}
        </button>
      </header>
    </div>
  );
};

export default VerificationCode;
