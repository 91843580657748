import shadow from "../files/shadow.png";
import React from "react";
import "./MasterCompanyLogged.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  faHome,
  faCalendarAlt,
  faHandHoldingUsd,
  faBriefcase,
  faDollarSign,
  faBell,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const MasterCompanyLogged = ({ title = "Home", children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToCompanyEmployees = () => {
    navigate("/CompanyEmployees");
  };
  const navigateToAbsenceSchedule = () => {
    navigate("/AbsenceSchedule");
  };
  return (
    <>
      <div className="master">
        <div className="master-header">
          <img src={shadow} className="header-shadow" alt="logo" />
          <h1 className="master-title">{title}</h1>
        </div>
        <div className="master-body">{children}</div>
        <div className="master-footer">
          <button
            className={
              "btn-footer " +
              (location.pathname == "/CompanyEmployees" ? "active-btn" : "")
            }
            onClick={navigateToCompanyEmployees}
          >
            <FontAwesomeIcon icon={faUserFriends} />
          </button>

          <button
            className={
              "btn-footer " +
              (location.pathname == "/AbsenceSchedule" ? "active-btn" : "")
            }
            onClick={navigateToAbsenceSchedule}
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
          </button>
          <button className="btn-footer btn-home">
            <FontAwesomeIcon icon={faHome} />
          </button>
          <button className="btn-footer">
            <FontAwesomeIcon icon={faDollarSign} />
          </button>
          <button className="btn-footer">
            <FontAwesomeIcon icon={faBell} />
          </button>
        </div>
      </div>
    </>
  );
};
export default MasterCompanyLogged;
