import shadow from "./files/shadow.png";
import entraNaFila from "./files/entra_na_fila_1.png";
import React from "react";
import { InputMask } from "@react-input/mask";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
Modal.setAppElement("#root");

const HomeCompany = () => {
  const [ignored, forceUpdate] = React.useReducer((o) => !o);
  const [showLoading, setShowLoading] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  function message(txt) {
    setShowModal(true);
    setMsg(txt);
  }
  function fecharModal() {
    setShowModal(false);
  }

  const modify = (input) => {
    return { mask: input[0] === "0" ? "+_ (___) ___-__-__" : undefined };
  };

  var phone = "";
  var isSms = false;
  const navigate = useNavigate();
  const onClickHandler = function () {
    // navigate(`/NoPage`);

    sessionStorage.setItem("phone", phone);
    setShowLoading(true);
    fetch(
      "https://yqk7t0y2w6.execute-api.us-east-1.amazonaws.com/Prod/User/login",
      {
        method: "POST",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phone,
          code: "",
          isWhatsapp: isSms === false,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setShowLoading(false);
        if (Array.isArray(response)) {
          message(response);
          return;
        }
        navigate(`/VerificationCode`);
      });
  };

  return (
    <>
      <Modal
        className="modal-body"
        isOpen={showModal}
        onRequestClose={fecharModal}
        contentLabel="Modal de exemplo"
      >
        <div className="modal-body">
          <div className="modal-header">
            <button className="btn-close-modal" onClick={fecharModal}>
              X
            </button>
            <h2 className="modal-title">Olá</h2>
          </div>
          <div className="modal-content">
            <div>
              {Array.isArray(msg)
                ? msg.map((item) => {
                    return <p>{item}</p>;
                  })
                : msg}
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn-close-modal-footer" onClick={fecharModal}>
              Fechar
            </button>
          </div>
        </div>
      </Modal>

      <div className="App">
        <header className="App-header">
          <img src={shadow} className="header-shadow" alt="logo" />
          <div className="container-logo">
            <img src={entraNaFila} className="header-entranafila" alt="logo" />
          </div>
          <label className="ft-1 clr-1 ft-sz-2">
            ENTRA <br /> NA FILA{" "}
          </label>
          <div className="box-border-entra-na-fila">
            <label className="label-for-title">Seu telefone</label>
            <InputMask
              placeholder="Ex: (11) 91111-2222"
              className="txt"
              mask="(__) _____-____"
              replacement={{ _: /\d/ }}
              modify={modify}
              onChange={(e) => {
                phone = e.target.value;
              }}
            />
          </div>

          <label className="color-x">
            <input
              className="check-x"
              type="checkbox"
              onChange={(e) => {
                isSms = !isSms;
              }}
            />
            Receber via SMS
          </label>

          <button className="btn-entra-na-fila" onClick={onClickHandler}>
            Entrar
            {showLoading == true ? (
              <FontAwesomeIcon icon={faSync} className={"spinner"} />
            ) : null}
          </button>
        </header>
      </div>
    </>
  );
};

export default HomeCompany;
