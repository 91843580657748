import React from "react";
import "./DiaSemanaHorarioInicioFim.css";
import TextField from "@mui/material/TextField";

const DiaSemanaHorarioInicioFim = ({ dia = "feira" }) => {
  const hours = [];
  for (var i = 0; i <= 23; i++) {
    hours.push(i);
  }
  const minutes = [];
  for (var i = 0; i <= 50; i += 10) {
    minutes.push(i);
  }

  return (
    <>
      <div className="day-week-time-box">
        <div className="check-title">
          <label className="lbl-day">{dia}</label>
          <div className="title-content-hourly">
            <div className="group-time-start-end">
              <div className="time-boxes">
                <label className="lbl-day">De:</label>
                <select defaultValue={-1} className="input-modal">
                  <option disabled value={-1}>
                    (Horas)
                  </option>
                  {hours.map((hour) => (
                    <option value={hour}>{hour}</option>
                  ))}
                </select>
                <select defaultValue={0} className="input-modal">
                  <option disabled value={0}>
                    (Minutos)
                  </option>
                  {minutes.map((minute) => (
                    <option value={minute}>{minute}</option>
                  ))}
                </select>
              </div>
              <div className="time-boxes">
                <label className="lbl-day">às:</label>
                <select defaultValue={-1} className="input-modal">
                  <option disabled value={-1}>
                    (Horas)
                  </option>
                  {hours.map((hour) => (
                    <option value={hour}>{hour} horas</option>
                  ))}
                </select>
                <select defaultValue={0} className="input-modal">
                  <option disabled value={0}>
                    (Minutos)
                  </option>
                  {minutes.map((minute) => (
                    <option value={minute}>{minute} minutos</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="right-btn">
              <input className="check-day" type="checkbox" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DiaSemanaHorarioInicioFim;
