import React from "react";
import Modal from "react-modal";
import ServiceItem from "./ServiceItem";
import "./ModalServices.css";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
Modal.setAppElement("#root");
const ModalServices = ({ show = false, title = "Home", onClose }) => {
  function fecharModal() {
    onClose(false);
  }
  return (
    <>
      <Modal
        className="modal-body"
        isOpen={show}
        onRequestClose={fecharModal}
        contentLabel="Modal de exemplo"
      >
        <div className="modal-body">
          <div className="modal-header">
            <label className="lbl-title">Serviços</label>
            <button className="btn-close-modal" onClick={fecharModal}>
              X
            </button>
            <h5 className="modal-title">{title}</h5>
          </div>
          <div className="modal-content-x">
            <div className="scheduler-content">
              <ServiceItem name="Corte" time="01:20" price="35,00" />
              <ServiceItem name="Corte + escova" time="01:40" price="60,00" />
              <ServiceItem
                name="Corte + progressiva"
                time="03:00"
                price="230,00"
              />
              <ServiceItem name="Corte + luzes" time="03:30" price="350,00" />
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn-float-new">
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="btn-close-modal-footer" onClick={fecharModal}>
              Fechar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalServices;
