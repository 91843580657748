import MasterCompanyLogged from "./MasterCompanyLogged";
import "./CompanyEmployees.css";
import ProfessionalCard from "../Components/ProfessionalCard";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CompanyEmployees = () => {
  return (
    <>
      <MasterCompanyLogged title="Profissionais">
        <div className="main-employees">
          <ProfessionalCard profName="Tamiris Andrade"></ProfessionalCard>
          <ProfessionalCard profName="Sheila lacerda"></ProfessionalCard>
          <ProfessionalCard profName="Maria Da Silva"></ProfessionalCard>
          <ProfessionalCard profName="José da Silva"></ProfessionalCard>
          <ProfessionalCard profName="Algusto da Silva"></ProfessionalCard>
        </div>
        <button className="btn-float-new">
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </MasterCompanyLogged>
    </>
  );
};

export default CompanyEmployees;
