import React from "react";
import "./ServiceItem.css";
import TextField from "@mui/material/TextField";
import CurrencyInput from "react-currency-input-field";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ServiceItem = ({ name = "", time = "0", price = "0" }) => {
  const hours = [];
  for (var i = 0; i <= 168; i++) {
    hours.push(i);
  }
  const minutes = [];
  for (var i = 0; i <= 55; i += 5) {
    minutes.push(i);
  }

  return (
    <>
      <div className="day-week-time-box-service">
        <div className="check-title-service">
          <div className="title-content">
            <div className="time-boxes-service"></div>
            <div className="time-boxes-service">
              <input
                type="text"
                className="input-name"
                defaultValue={name}
                placeholder="Nome do serviço"
              />
            </div>
            <div className="time-boxes-service">
              <select defaultValue={-1} className="input-modal">
                <option disabled value={-1}>
                  (Horas)
                </option>
                {hours.map((hour) => (
                  <option value={hour}>{hour} horas</option>
                ))}
              </select>
              <select defaultValue={0} className="input-modal">
                <option disabled value={0}>
                  (Minutos)
                </option>
                {minutes.map((minute) => (
                  <option value={minute}>{minute} minutos</option>
                ))}
              </select>
            </div>
            <div className="time-boxes-service">
              <CurrencyInput
                intlConfig={{ locale: "pt-BR", currency: "GBP" }}
                className="input-modal"
                id="input-example"
                name="input-name"
                placeholder="Preço R$"
                decimalSeparator=","
                defaultValue={0}
                decimalsLimit={2}
                step={1}
                prefix="R$ "
                suffix=""
                groupSeparator="."
                fixedDecimalLength={2}
                onValueChange={(value, name, values) =>
                  console.log(value, name, values)
                }
              />
            </div>
          </div>
        </div>
        <div className="right-btn">
          <button className="btn-prof-option btn-fixed-right-top">
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
      </div>
    </>
  );
};
export default ServiceItem;
