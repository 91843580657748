import React from "react";
import Modal from "react-modal";
import DiaSemanaHorarioInicioFim from "./DiaSemanaHorarioInicioFim";
import "./ModalAgenda.css";
Modal.setAppElement("#root");
const ModalAgenda = ({ show = false, title = "Home", onClose }) => {
  function fecharModal() {
    onClose(false);
  }
  return (
    <>
      <Modal
        className="modal-body"
        isOpen={show}
        onRequestClose={fecharModal}
        contentLabel="Modal de exemplo"
      >
        <div className="modal-body">
          <div className="modal-header">
            <label className="lbl-title">Agenda</label>
            <button className="btn-close-modal" onClick={fecharModal}>
              X
            </button>
            <h5 className="modal-title">{title}</h5>
          </div>
          <div className="modal-content-x">
            <div className="scheduler-content">
              <DiaSemanaHorarioInicioFim dia="Domingo" />
              <DiaSemanaHorarioInicioFim dia="Segunda-feira" />
              <DiaSemanaHorarioInicioFim dia="Terça-feira" />
              <DiaSemanaHorarioInicioFim dia="Quarta-feira" />
              <DiaSemanaHorarioInicioFim dia="Quinta-feira" />
              <DiaSemanaHorarioInicioFim dia="Sexta-feira" />
              <DiaSemanaHorarioInicioFim dia="Sábado" />
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn-close-modal-footer" onClick={fecharModal}>
              Fechar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalAgenda;
