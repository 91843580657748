import React from "react";
import Modal from "react-modal";
import "./ModalAbsenceFromWork.css";
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

Modal.setAppElement("#root");

const ModalServices = ({ show = false, title = "Home", onClose }) => {
  function fecharModal() {
    onClose(false);
  }
  const hours = [];
  for (var i = 0; i <= 23; i++) {
    hours.push(i);
  }
  const minutes = [];
  for (var i = 0; i <= 55; i += 5) {
    minutes.push(i);
  }
  return (
    <>
      <Modal
        className="modal-body"
        isOpen={show}
        onRequestClose={fecharModal}
        contentLabel="Modal de exemplo"
      >
        <div className="modal-body">
          <div className="modal-header">
            <label className="lbl-title">Ausências</label>
            <button className="btn-close-modal" onClick={fecharModal}>
              X
            </button>
            <h5 className="modal-title">{title}</h5>
          </div>
          <div className="modal-content-x-absence">
            <div className="lunch-box">
              <div className="lunch-content">
                <label className="lbl-tb-title">Hora do almoço</label>
                <div className="lunch-controls">
                  <div className="time-boxes-lunch">
                    <label className="lbl-day-lunch">De:</label>
                    <select defaultValue={-1} className="input-modal-lunch">
                      <option disabled value={-1}>
                        (Horas)
                      </option>
                      {hours.map((hour) => (
                        <option value={hour}>{hour}</option>
                      ))}
                    </select>
                    :
                    <select defaultValue={0} className="input-modal-lunch">
                      <option disabled value={0}>
                        (Minutos)
                      </option>
                      {minutes.map((minute) => (
                        <option value={minute}>{minute}</option>
                      ))}
                    </select>
                  </div>
                  <div className="time-boxes-lunch">
                    <label className="lbl-day-lunch">às:</label>
                    <select defaultValue={-1} className="input-modal-lunch">
                      <option disabled value={-1}>
                        (Horas)
                      </option>
                      {hours.map((hour) => (
                        <option value={hour}>{hour}</option>
                      ))}
                    </select>
                    :
                    <select defaultValue={0} className="input-modal-lunch">
                      <option disabled value={0}>
                        (Minutos)
                      </option>
                      {minutes.map((minute) => (
                        <option value={minute}>{minute}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="right-border-lunch">
                <input className="check-day-lunch" type="checkbox" />
              </div>
            </div>
            <div className="table-box">
              <div className="table-calendar-headers">
                <button className="btn-calendar">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <label className="lbl-tb-title">Março 2024</label>
                <button className="btn-calendar">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
              <table className="table-calendar">
                <thead>
                  <th>D</th>
                  <th>S</th>
                  <th>T</th>
                  <th>Q</th>
                  <th>Q</th>
                  <th>S</th>
                  <th>S</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        25
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        26
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        27
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        28
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        29
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar">1</button>
                    </td>
                    <td>
                      <button className="btn-calendar">2</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="btn-calendar">3</button>
                    </td>
                    <td>
                      <button className="btn-calendar">4</button>
                    </td>
                    <td>
                      <button className="btn-calendar">5</button>
                    </td>
                    <td>
                      <button className="btn-calendar">6</button>
                    </td>
                    <td>
                      <button className="btn-calendar">7</button>
                    </td>
                    <td>
                      <button className="btn-calendar">8</button>
                    </td>
                    <td>
                      <button className="btn-calendar">9</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="btn-calendar">10</button>
                    </td>
                    <td>
                      <button className="btn-calendar">11</button>
                    </td>
                    <td>
                      <button className="btn-calendar">12</button>
                    </td>
                    <td>
                      <button className="btn-calendar">13</button>
                    </td>
                    <td>
                      <button className="btn-calendar">14</button>
                    </td>
                    <td>
                      <button className="btn-calendar">15</button>
                    </td>
                    <td>
                      <button className="btn-calendar">16</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="btn-calendar">17</button>
                    </td>
                    <td>
                      <button className="btn-calendar">18</button>
                    </td>
                    <td>
                      <button className="btn-calendar">19</button>
                    </td>
                    <td>
                      <button className="btn-calendar">20</button>
                    </td>
                    <td>
                      <button className="btn-calendar">21</button>
                    </td>
                    <td>
                      <button className="btn-calendar">22</button>
                    </td>
                    <td>
                      <button className="btn-calendar">23</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="btn-calendar">24</button>
                    </td>
                    <td>
                      <button className="btn-calendar">25</button>
                    </td>
                    <td>
                      <button className="btn-calendar">26</button>
                    </td>
                    <td>
                      <button className="btn-calendar">27</button>
                    </td>
                    <td>
                      <button className="btn-calendar">28</button>
                    </td>
                    <td>
                      <button className="btn-calendar">29</button>
                    </td>
                    <td>
                      <button className="btn-calendar">30</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="btn-calendar">31</button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        1
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        2
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        3
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        4
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        5
                      </button>
                    </td>
                    <td>
                      <button className="btn-calendar day-out-of-month">
                        6
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn-close-modal-footer" onClick={fecharModal}>
              Fechar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalServices;
