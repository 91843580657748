import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  sessionStorage.setItem("item_key", "markus");
  return (
    <>
      <div className="flex-body">
        <div className="flex-body">
          <Outlet />
        </div>
        {/* <div className="flex-footer"> */}
        {/* <nav> */}
        {/* <ul> */}
        {/* <li> */}
        {/* sessionStorage.setItem("item_key", item_value); */}
        {/* <Link to="/">Home x {sessionStorage.getItem("item_key")}</Link> */}
        {/* </li> */}
        {/* <li> */}
        {/* <Link to="/HomeCompany">Home Company</Link> */}
        {/* </li> */}
        {/* </ul> */}
        {/* </nav> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Layout;
